import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import img1 from '../assets/images/sobre.jpeg';

function AppSobre() {

  return (
    <section id="sobre" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Sobre Nós</h2>
          <div className="subtitle">saiba mais sobre nós</div>
        </div>
        <Row className="justify-content-center">
          <Col sm={4} className="offset-sm-1"> 
            <Image src={img1} className="img-fluid" />
          </Col>
          <Col sm={5} className="offset-sm-1"> 
            <p>Fundado por Isabela Pádua, formada em fisioterapia desde 2019 pela Universidade de Franca - Unifran, e instrutora de pilates desde 2019. Pós graduada em fisioterapia cardiorrespiratória geral e intensiva pela Claretiano e com formação em pilates solo e aparelhos realizada em 2018, pilates para gestantes pela Metafisio Pilates em 2019 e formação em Quiropraxia clínica ministrada pelo Dr. Eduardo Miranda em 2020.</p>
            <p>No Studio de pilates Isabela Pádua oferecemos sessões de pilates personalizados, atendimentos de pilates para gestantes, pilates para idosos em uma das melhores regiões da cidade de Franca. As aulas são ministradas por Fisioterapeuta, com pacotes individuais ou turmas com até 4 pessoas, sempre visando a qualidade e seu conforto.</p>
            <p>Além disso, também trabalhamos com fisioterapia clínica, sessões de quiropraxia e liberação miofascial, recovery e atendimentos domiciliares na área de geriatria e neurologia.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppSobre;