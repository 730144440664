import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import AppHeader from './components/header';
import AppHero from './components/hero';
import AppSobre from './components/sobre';
import AppServicos from './components/servicos';
import AppEquipe from './components/equipe';
import AppFotos from './components/fotos';
import AppContato from './components/contato';
import AppFooter from './components/footer';

function App() {
  return (
    <div className="App">
      <header id="header">
        <AppHeader />
      </header>
      <main>
        <AppHero />
        <AppSobre />
        <AppServicos />
        <AppEquipe />
        <AppFotos />
        <AppContato />        
      </main>
      <footer id="footer">
        <AppFooter />
      </footer>
    </div>
  );
}

export default App;
