import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import img1 from '../assets/images/equipe1.jpeg';
import img2 from '../assets/images/equipe2.jpeg';
import img3 from '../assets/images/equipe3.jpeg';

function AppEquipe() {
  return (
    <section id="equipe" className="block equipe-block">
      <Container>
        <div className="title-holder text-center">
          <h2>Nossa Equipe</h2>
          <div className="subtitle">Saiba mais sobre nós</div>
        </div>
        <Row className="justify-content-center">
          <Col xs={12} sm={4} md={3} className="d-flex justify-content-center">
            <Card className="text-center custom-card">
              <Card.Img variant="top" src={img1} className="img-fluid" />
              <Card.Body>
                <Card.Title>
                  <strong>Isabela Pádua</strong>
                </Card.Title>
                <Card.Text>Graduada em Fisioterapia em 2019 pela Unifran, pós-graduada em fisioterapia cardiorrespiratória geral e intensiva pela Claretiano com formação em Pilates solo, aparelhos e para gestantes pela Metafisio.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={4} md={3} className="d-flex justify-content-center">
            <Card className="text-center custom-card">
              <Card.Img variant="top" src={img2} className="img-fluid" />
              <Card.Body>
                <Card.Title>
                  <strong>Helena Papacídero</strong>
                </Card.Title>
                <Card.Text>Graduada em Fisioterapia no Centro Universitário Claretiano de Batatais, atuando como Fisioterapeuta Intensivista Neonatal e Pediátrica na Santa Casa de Franca. Com formação em Pilates solo e aparelhos pela Metafisio.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={4} md={3} className="d-flex justify-content-center">
            <Card className="text-center custom-card">
              <Card.Img variant="top" src={img3} className="img-fluid" />
              <Card.Body>
                <Card.Title>
                  <strong>Maria Eduarda</strong>
                </Card.Title>
                <Card.Text>Graduada em Fisioterapia em 2021 pela Unifran, com curso em liberação miofascial e kinesio tapping em 2024 e com formação em Pilates solo e aparelhos pela Metafisio.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppEquipe;
